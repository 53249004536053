import React, {Component} from "react";
import {ReactComponent as ArrowRight} from "../images/arrow-right.svg";
import {getAllDeposits} from "../actions/deposits";
import {getAccountBalance} from "../actions/accountBalance";
import {connect} from "react-redux";
import {formatMoney} from "../components/utils";
import {Trans, withTranslation} from "react-i18next";
import {setActiveTab} from "../actions/tabs";
import {ReactComponent as InfoBtn} from "../images/round-info-button.svg";
import {ReactComponent as CloseBtn} from "../images/baseline-close-24px.svg";
import {getAllUserInvestments} from "../actions/investment";

const mapStateToProps = state => {
    return {
        balanceHistory: state.graph.balanceHistory,
        user: state.user.currentUser,
        accountBalance: state.accountBalance.accountBalance,
        deposits: state.deposits.deposits,
        depositIds: state.deposits.depositIds,
        atEnd: state.deposits.atEnd,
        direction: state.deposits.direction,
        sort: state.deposits.sort,
        limit: state.deposits.limit,
        offset: state.deposits.offset,
        _activeTab: state.tabs.tab
    };
};

class AppDeposit extends Component {
    render() {
        const {user, accountBalance, deposits, t, atEnd} = this.props;
        return (
            <section className={this.props._activeTab === 0 ? "page page--prev active" : "page page--prev not-active"}>
                <div className="page__inner">

                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-7-desktop  mdc-layout-grid__cell--span-12-tablet">
                                <h2 className="mdc-typography--headline5 title title--border">{t('depositSummaryTitle')}</h2>
                                <i className="title-spacer"/>
                                <div className="table-responsive">
                                    <table id="sortable-table" className="sortable table table--no-wrap">
                                        <thead>
                                        <tr className="mdc-typography--body2 table__head-row">
                                            <th className="no-sort"/>
                                            <th>{t('status')}</th>
                                            <th>{t('date')}</th>
                                            <th className="hide-on-mobile">{t('transactionType')}</th>
                                            <th>{t('amount')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!atEnd && (
                                            <tr>
                                                <td colSpan="5" style={{textAlign: "center"}}>
                                                    <button
                                                        type="button"
                                                        onClick={this._loadMore.bind(this)}
                                                        className="button button--unelevated"
                                                        style={{display: "inline-block", padding: "5px", height: "26px", margin: "0", lineHeight: "1", minWidth: "48px"}}>
                                                        ...
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                        {Object.keys(deposits || {}).map(id => {
                                            const deposit = deposits[id];
                                            return (
                                                <tr className="mdc-typography--body1" key={id}>
                                                    {deposit.status === "PROCESSED" ? (
                                                        <td className="bullet green"/>) : (
                                                        <td className="bullet white"/>)}
                                                    <td><Trans i18nKey={deposit.status}
                                                               ns={"app"}>{deposit.status}</Trans></td>
                                                    <td>{deposit.dateFormatted}</td>
                                                    <td className="hide-on-mobile">{t('bankTransfer')}</td>
                                                    <td className="table-number">{formatMoney(deposit.value)} €</td>
                                                </tr>
                                            )
                                        })}
                                        <tr className="mdc-typography--body1 table__total">
                                            <td/>
                                            <td/>
                                            <td className="hide-on-mobile"/>
                                            <td><b>{t('total')}</b></td>
                                            <td><b>{formatMoney(accountBalance.depositsTotal)} €</b></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/*<div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1-desktop no-small-screen"/>*/}
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-12-tablet">
                                <div ref="card" className="card card--flip">
                                    <div className="card__surface">
                                        <div
                                            className={"mdc-menu-surface--anchor card__more"}>
                                            <button ref="infoBtn"
                                                    className="icon-button icon-button--dense card__info-btn">
                                                <InfoBtn/>
                                            </button>
                                        </div>
                                        <div className="table-fixed">
                                            <h2 className="mdc-typography--headline5 title title--border">{t('depositNowTitle')}</h2>
                                            <i className="title-spacer"/>
                                            <div className="table-fixed">
                                                <table className="sortable table">
                                                    <tbody className="mdc-typography--body1">
                                                    <tr>
                                                        <td>{t('investorAccountNumber')}</td>
                                                        <td className="table-number bold" align="right">{user.Id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('nameOfBeneficiary')}</td>
                                                        <td className="table-number bold" align="right">Nekster d.o.o.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('addressOfBeneficiary')}</td>
                                                        <td className="table-number bold" align="right">Dunajska cesta
                                                            151, 1000 LJubljana
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('bankAccountNumber')}</td>
                                                        <td className="table-number bold" align="right">SI56 3300 0001 3170 263</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('bankName')}</td>
                                                        <td className="table-number bold" align="right">Addiko Bank d.d.</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card__back surface--with-text">
                                            <h4 className="mdc-typography--subtitle2 surface__title">{t('info')}</h4>
                                            <div className="card__back-scroll-content">
                                                <p className="mdc-typography--body2"><Trans ns="app"
                                                                                            i18nKey="depositInfo"><br/></Trans>
                                                </p>
                                            </div>
                                            <div className="card__surface-action">

                                                <a href="https://www.nekster.com/faq-category/racun/" target="_blank"
                                                   rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                                    <button className="button">
                                                        {t('depositInfoAction')}
                                                    </button>
                                                </a>

                                            </div>
                                            <div className="card__more">
                                                <button ref="closeBtn"
                                                        className="icon-button icon-button--dense icon-button--untouched card__info-btn">
                                                    <CloseBtn/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-navigation-wrapper" ref="withdrawBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t('withdrawSummaryTitle')}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowRight/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    _loadMore() {
        this.props.dispatch(getAllDeposits({
            offset: this.props.offset + 1,
            sort: this.props.sort,
            direction: this.props.direction
        }));
    }

    componentDidMount() {
        /*let tablesort = require('tablesort');
        tablesort(document.getElementById('sortable-table'), {});*/

        this.refs.withdrawBtn.addEventListener('click', () => {
            this.props.dispatch(setActiveTab(1))
        });
        this.props.dispatch(getAllDeposits());
        this.props.dispatch(getAccountBalance());


        // card flipping
        this._infoListener = e => {
            this.refs.card.classList.toggle("card--is-flipped")
        };
        this._closeListener = e => {
            this.refs.card.classList.toggle("card--is-flipped")
        };
        this.refs.infoBtn.addEventListener('click', this._infoListener);
        this.refs.closeBtn.addEventListener('click', this._closeListener);
    }
}

export default withTranslation('app')(connect(mapStateToProps)(AppDeposit));
